import styled from 'styled-components'
import SearchIcon from '../assets/svg/search.svg'
import CategoryIcon from '../assets/svg/categories-icon.svg'
import CrossIcon from '../assets/svg/plus.svg'
import { urlPathJoin } from '../utils/locales'
import { useIntl } from '../utils/IntlContext'
import Link from './Link'
import media from '../utils/media'
import { useRef, useState } from 'react'
import BlogSearch from './BlogSearch'

const CategoryBanner = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: calc(var(--nav-header-height) + var(--spacing-md)) var(--spacing-md) 0;
`

const Title = styled.h1`
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
  padding-top: 70px;
  text-align: initial;

  ${media.sm`
    padding-top: 0;
    margin-bottom: 0;
  `}
`

const TitleWrapper = styled.div`
  display: block;
  padding-bottom: 8px;

  ${media.sm`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto auto 24px;
    padding-bottom: unset;
  `}
`

const StyledSearchIcon = styled(SearchIcon)`
  display: none;
  cursor: pointer;

  ${media.sm`
    display: block;
  `}
`

const StyledCategoryIcon = styled(CategoryIcon)`
  display: block;
  cursor: pointer;
`

const CategoriesWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: fit-content;
  position: fixed;
  width: 100vw;
  z-index: 10;
  padding: 20px;
  margin: 0;
  top: calc(var(--nav-header-height) + var(--spacing-xsm));
  left: 0;
  bottom: 0;
  transition: all 0.5s;
  overflow: auto;
  background: white;
  box-shadow: var(--box-shadow-primary-small);
  transform: ${({ $displayCategories }) => ($displayCategories ? 'translateX(0)' : 'translateX(-100vw)')};
  opacity: ${({ $displayCategories }) => ($displayCategories ? '1' : '0')};

  ${media.sm`
    height: unset;
    flex-direction: unset;
    margin: unset;
    box-shadow: unset;
    width: unset;
    padding: unset;
    transform: translateX(0);
    opacity: 1;
    position: relative;
    top: unset;
    z-index: 0;
  `}
`

const CategoryItem = styled.li`
  font-weight: ${({ $boldTitle }) => ($boldTitle ? '600' : 'normal')};
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  margin: unset;
  cursor: pointer;
  border-bottom: 1px solid var(--color-background-light);

  &:last-child {
    border-bottom: unset;
  }

  ${media.sm`
    margin-right: min(32px, 5%);
    margin-bottom: 16px;
    width: unset;
    height: unset;
    padding: unset;
    border-bottom: unset;
  `}
`

const CategorySubNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100vw;
  padding: 12px 15px 12px 16px;
  margin: calc(var(--spacing-md) * -1) 0 0 calc(var(--spacing-md) * -1);
  position: fixed;
  z-index: ${({ $displayCategories }) => ($displayCategories ? '10' : '9')};
  background: white;
  box-shadow: var(--box-shadow-primary-small);

  ${StyledSearchIcon} {
    display: block;
  }

  ${media.sm`
    display: none;
  `}
`

const SubNavMask = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  transition: all 0.5s;
  z-index: ${({ $displayMask }) => ($displayMask ? '9' : '-1')};
  opacity: ${({ $displayMask }) => ($displayMask ? '0.8' : '0')};

  ${media.sm`
    z-index: -1;
    opacity: 0;
  `}
`

const CategoriesController = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  width: 100vw;
  justify-content: space-between;
  padding: 24px;
  margin-left: -20px;
  background: white;
  top: calc(var(--nav-header-height) * -1);
  transition: all 0.5s;
  opacity: ${({ $displayCategories }) => ($displayCategories ? '1' : '0')};
  transform: ${({ $displayCategories }) => ($displayCategories ? 'translateX(0)' : 'translateX(-101vw)')};
`

const ControllerTitle = styled.h2`
  margin-bottom: 0;
`

const StyledCrossIcon = styled(CrossIcon)`
  transform: rotate(45deg);
  cursor: pointer;
`

const TotalPosts = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  text-align: left;

  ${media.sm`
    display: none;
  `}
`

const BlogCategoryBanner = ({ pageBasePath, categories, currentPage, totalPosts, postsDisplayed, currentCategory }) => {
  const [displayCategories, setDisplayCategories] = useState(false)
  const [displaySearch, setDisplaySearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [inputPlaceholder, setInputPlaceholder] = useState(null)
  const bannerRef = useRef(null)

  const { t } = useIntl()

  const lowerRangeDisplayed = currentPage > 1 ? 10 * (currentPage - 1) : 10
  const upperRangeDisplayed = 10 * currentPage >= totalPosts ? totalPosts : 10 * currentPage
  const postRange = currentPage === 1 ? postsDisplayed : `${lowerRangeDisplayed} - ${upperRangeDisplayed}`

  const handleSearchClose = () => {
    setDisplaySearch(false)
    setSearchQuery('')
  }

  const handleSearchInput = (input) => {
    setSearchQuery(input)
  }

  const handleSearchOpen = () => {
    setDisplaySearch(true)
    if (bannerRef.current?.clientWidth < 500) {
      setInputPlaceholder('')
    } else {
      setInputPlaceholder(t('search'))
    }
  }

  return (
    <CategoryBanner ref={bannerRef}>
      {displaySearch && (
        <BlogSearch
          inputPlaceholder={inputPlaceholder}
          handleSearchClose={handleSearchClose}
          handleSearchInput={handleSearchInput}
          searchQuery={searchQuery}
          pageBasePath={pageBasePath}
        />
      )}
      <TitleWrapper>
        <CategorySubNav $displayCategories={displayCategories}>
          <CategoriesController $displayCategories={displayCategories}>
            <ControllerTitle>{t('categories')}</ControllerTitle>
            <StyledCrossIcon onClick={() => setDisplayCategories(!displayCategories)} />
          </CategoriesController>
          <StyledCategoryIcon onClick={() => setDisplayCategories(!displayCategories)} />
          <StyledSearchIcon onClick={() => handleSearchOpen()} />
        </CategorySubNav>
        <Title>{currentCategory || t('visualcv_blog')}</Title>
        {currentCategory && <TotalPosts>{t('results_displayed', { postRange, totalPosts })}</TotalPosts>}
        <StyledSearchIcon onClick={() => handleSearchOpen()} />
      </TitleWrapper>
      <CategoriesWrapper $displayCategories={displayCategories}>
        <CategoryItem $boldTitle={!categories.find(({ title }) => title === currentCategory)}>
          <Link to={pageBasePath}>{t('all_posts')}</Link>
        </CategoryItem>
        {categories.map((category, index) => (
          <CategoryItem
            key={`categoryItem${index}`}
            $boldTitle={category.title === currentCategory}>
            <Link to={urlPathJoin(pageBasePath, category.slug)}>{category.title}</Link>
          </CategoryItem>
        ))}
      </CategoriesWrapper>
      <SubNavMask
        onClick={() => handleSearchClose()}
        $displayMask={displayCategories || displaySearch}
      />
    </CategoryBanner>
  )
}

export default BlogCategoryBanner
