import { graphql } from 'gatsby'
import { styled } from 'styled-components'
import BlogCategoryBanner from '../components/BlogCategoryBanner'
import BlogListing from '../components/BlogListing'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import Pagination from '../components/Pagination'
import Wrapper from '../components/Wrapper'
import { useIntl } from '../utils/IntlContext'
import { getLocalePathPrefix, urlPathJoin } from '../utils/locales'

export const query = graphql`
  query Blog($contentful_id: String!, $skip: Int!, $limit: Int!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: "1V51KAm4h66KiLS1F7scdV" }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
    }
    allContentfulBlogPost(
      filter: {
        slug: { nin: [null, ""] }
        title: { nin: [null, ""] }
        content: { childMarkdownRemark: { html: { nin: [null, ""] } } }
        node_locale: { eq: $locale }
        blog_category: { elemMatch: { contentful_id: { regex: $contentful_id } } }
      }
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          ...BlogListingItem
        }
      }
    }
    allContentfulBlogCategory(
      filter: {
        node_locale: { eq: $locale }
        blogs: { elemMatch: { slug: { regex: "/.*\\S+.*/" } } }
        slug: { nin: [null, ""] }
        categoryTitle: { nin: [null, ""] }
      }
    ) {
      edges {
        node {
          title: categoryTitle
          slug
          categoryContentfulId: contentful_id
        }
      }
    }
    ...BlogCta
  }
`

const StyledPagination = styled(Pagination)`
  margin-bottom: var(--spacing-md);
`

const Blog = ({
  pageContext: { translatedPages, numPages, currentPage, contentful_id },
  data: {
    contentfulReactPage: { slug },
    allContentfulBlogPost,
    blogCtaCardImage,
    blogCtaCardLink,
    allContentfulBlogCategory,
  },
}) => {
  const { locale } = useIntl()
  const pageBasePath = urlPathJoin(getLocalePathPrefix(locale), slug)
  const categories = allContentfulBlogCategory.edges.map((node) => node.node)
  const currentCategory = categories.find(({ categoryContentfulId }) => `/^${categoryContentfulId}$/` === contentful_id)
  const paginationPath = currentCategory ? urlPathJoin(pageBasePath, currentCategory.slug) : pageBasePath

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <>
        <BlogCategoryBanner
          pageBasePath={pageBasePath}
          pageContentfulId={contentful_id}
          currentCategory={currentCategory?.title}
          categories={categories}
          currentPage={currentPage}
          totalPosts={allContentfulBlogPost.totalCount}
          postsDisplayed={allContentfulBlogPost.edges.length}
        />
        <Wrapper>
          <BlogListing
            parentBasePath={pageBasePath}
            blogPosts={allContentfulBlogPost.edges.map((edge) => edge.node)}
            showFeatured={currentPage === 1}
            blogCtaCardImage={blogCtaCardImage}
            blogCtaCardLink={blogCtaCardLink}
            totalPosts={allContentfulBlogPost.totalCount}
          />
          <StyledPagination
            basePath={paginationPath}
            pageCount={numPages}
            currentPage={currentPage}
          />
        </Wrapper>
      </>
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default Blog
