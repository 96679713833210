import { graphql, useStaticQuery } from 'gatsby'
import { urlPathJoin } from '../../utils/locales'

const useBlogData = (locale, pageBasePath) => {
  const { searchPosts } = useStaticQuery(graphql`
    query UseBlogData {
      searchPosts: allContentfulBlogPost(filter: { slug: { nin: [null, ""] }, title: { nin: [null, ""] } }) {
        edges {
          node {
            title
            slug
            node_locale
          }
        }
      }
    }
  `)
  return searchPosts.edges
    .filter((edge) => edge.node.node_locale === locale)
    .map((edge) => ({
      title: edge.node.title,
      slug: urlPathJoin(pageBasePath, edge.node.slug),
    }))
}

export default useBlogData
