import styled, { keyframes } from 'styled-components'
import CrossIcon from '../assets/svg/plus.svg'
import SearchIcon from '../assets/svg/search.svg'
import useBlogData from '../hooks/queries/useBlogData'
import { useIntl } from '../utils/IntlContext'
import { localeEquals, localeIncludes } from '../utils/locales'
import media from '../utils/media'
import Link from './Link'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  display: none;
  cursor: pointer;

  ${media.sm`
    display: block;
  `}
`

const StyledCrossIcon = styled(CrossIcon)`
  transform: rotate(45deg);
  cursor: pointer;
`

const SearchInput = styled.input`
  height: 48px;
  width: 100%;
  border: unset;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-typography-dark);
  outline: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-black-small);
  border: solid 1px var(--color-grey);
  padding-left: 48px;
  max-width: 1110px;

  &::placeholder {
    opacity: 0.8;
    color: var(--color-typography-dark);
  }

  &:focus {
    border: solid 1px var(--color-primary);
  }

  ${media.sm`
    font-size: 32px;
    height: 70px;
  `}
`

const SearchModal = styled.div`
  position: fixed;
  padding: calc(var(--nav-header-height) + var(--spacing-md)) var(--spacing-md) var(--spacing-md);
  width: 100vw;
  top: 0;
  left: 0;
  background: var(--color-background-white);
  box-shadow: var(--box-shadow-primary-small);
  z-index: 11;
  animation: ${fadeIn} 0.3s ease-in;
  opacity: 1;
  text-align: center;

  ${StyledSearchIcon} {
    display: block;
    position: absolute;
    margin: auto auto auto;
    bottom: 36px;
    left: 36px;

    g {
      fill: var(--color-grey);
    }

    ${media.sm`
      bottom: 63px;
      left: 0;
      right: min(1055px, calc(100vw - 365px));
      margin: auto;

      g {
        fill: var(--color-primary);
      }
    `}
  }

  ${SearchInput}:focus ~ ${StyledSearchIcon} {
    g {
      fill: var(--color-primary);
    }
  }

  ${media.sm`
    padding: 58px 157px 40px;
  `}
`

const SearchCloseBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--spacing-md) var(--spacing-md) 0;

  ${StyledCrossIcon} {
    float: right;
    pointer-events: all;
  }

  ${media.sm`
    padding: 30px 120px 0;
    max-width: 1425px;
    margin: auto;
    right: 0;
  `}
`

const SearchBarTitle = styled.div`
  visibility: visible;
  font-size: 24px;
  font-weight: 600;

  ${media.sm`
    visibility: hidden;
  `}
`

const SearchResults = styled.div`
  position: fixed;
  width: 100vw;
  left: 0;
  top: 144px;
  bottom: 0;
  padding: var(--spacing-md);
  z-index: 10;
  background: white;
  text-align: left;
  overflow: auto;
  animation: ${fadeIn} 0.3s ease-in;
  opacity: 1;

  ${media.sm`
    top: 168px;
    padding: var(--spacing-md) 158px;
  `}
`

const SearchResultsList = styled.ul`
  list-style: none;
  margin-left: 0;
`

const SearchResult = styled.li`
  line-height: 24px;
  margin-bottom: var(--spacing-md);
`

const SearchResultsTitle = styled.h3`
  font-size: 24px;
`

const BlogSearch = ({ inputPlaceholder, handleSearchClose, handleSearchInput, searchQuery, pageBasePath }) => {
  const { t, locale } = useIntl()
  const searchPosts = useBlogData(locale, pageBasePath)
  const filteredSearchPosts = searchPosts.filter((post) => localeIncludes(post.title, searchQuery))

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return parts.map((part, index) => (localeEquals(part, highlight) ? <b key={`${part}_${index}`}>{part}</b> : part))
  }

  return (
    <>
      <SearchModal>
        <SearchCloseBar>
          <SearchBarTitle>{t('search')}</SearchBarTitle>
          <StyledCrossIcon onClick={() => handleSearchClose()} />
        </SearchCloseBar>
        <SearchInput
          placeholder={inputPlaceholder}
          type="text"
          autoFocus
          onChange={(e) => handleSearchInput(e.target.value)}
        />
        <StyledSearchIcon />
      </SearchModal>
      {searchQuery && filteredSearchPosts.length && (
        <SearchResults>
          <SearchResultsTitle>{t('suggested_posts')}</SearchResultsTitle>
          <SearchResultsList>
            {filteredSearchPosts.map((post, index) => (
              <SearchResult key={index}>
                <Link to={post.slug}>{getHighlightedText(post.title, searchQuery)}</Link>
              </SearchResult>
            ))}
          </SearchResultsList>
        </SearchResults>
      )}
    </>
  )
}

export default BlogSearch
